<template>
  <HXContentCard
    title="Nuevo Plan Mejoramiento"
    :toBack="{ name: 'PlanMejoramiento' }"
  >
    <form @submit.prevent="saveData">
      <div class="row p-4">
        <!-- usuario asignado -->
        <div class="col-xl-6 mb-3">
          <div>
            <label for=" " class="form-label">
              <em class="text-danger font-weight-bolder">Paso 1</em> -
              Seleccionar Usuario</label
            >
            <!-- vue-select -->
            <div style="background: transparent; padding: 0rem">
              <v-select
                class="style-chooser"
                @input="getPersonaUbicacion(planMejoramiento.personaId)"
                :reduce="(value) => value.id"
                :options="listaPersonas"
                :get-option-label="
                  (option) => `${option.nombres} ${option.apellidos}`
                "
                v-model="planMejoramiento.personaId"
                placeholder="-- seleccionar persona --"
              >
                <!-- required -->
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!planMejoramiento.personaId"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>

                <!--  agregar contenido adicional a las opciones-->
                <template v-slot:option="option">
                  <!-- promedio -->
                  <b-badge
                    v-if="option.promedioEval"
                    :variant="
                      option.promedioEval <= 65
                        ? 'danger'
                        : option.promedioEval < 100
                        ? 'warning'
                        : 'success'
                    "
                    ><i
                      :class="`fas fa-${
                        option.promedioEval <= 65
                          ? 'arrow-down'
                          : option.promedioEval < 100
                          ? 'arrow-up'
                          : 'crown'
                      }`"
                    ></i>
                    {{ option.promedioEval }}</b-badge
                  >&nbsp;
                  {{ `${option.nombres} ${option.apellidos}` }}
                </template>

                <!-- sin resultados en la busquedad -->
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    No se encontraron resultados para
                    <em class="text-danger">{{ search }}</em
                    >.
                  </template>
                  <em v-else style="opacity: 0.5"
                    >No tienes personas a cargo.</em
                  >
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <!-- competencia por cargo -->
        <div class="col-xl-6 mb-3">
          <div>
            <label for=" " class="form-label"
              ><em class="text-danger font-weight-bolder">Paso 2</em> -
              Seleccionar Competencia</label
            >
            <!-- vue-select -->
            <div style="background: transparent; padding: 0rem">
              <v-select
                class="style-chooser"
                @input="
                  getActividadesByCompetenciaId(planMejoramiento.competenciaId)
                "
                :reduce="(value) => value.competencia.id"
                :options="cargoCompetencias"
                :get-option-label="(option) => option.competencia.titulo"
                v-model="planMejoramiento.competenciaId"
                placeholder="-- seleccionar competencia --"
              >
                <!-- required -->
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="!planMejoramiento.competenciaId"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>

                <!--  agregar contenido adicional a las opciones-->
                <template v-slot:option="option">
                  <b-badge
                    v-if="option.competencia.promedio"
                    :variant="
                      option.competencia.promedio <= 65
                        ? 'danger'
                        : option.competencia.promedio < 100
                        ? 'warning'
                        : 'success'
                    "
                    ><i
                      :class="`fas fa-${
                        option.competencia.promedio <= 65
                          ? 'arrow-down'
                          : option.competencia.promedio < 100
                          ? 'arrow-up'
                          : 'crown'
                      }`"
                    ></i>
                    {{ option.competencia.promedio }}
                  </b-badge>
                  &nbsp;
                  {{ option.competencia.titulo }}
                </template>

                <!-- sin resultados en la busquedad -->

                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    No se encontraron resultados para
                    <em class="text-danger">{{ search }}</em
                    >.
                  </template>
                  <em v-else style="opacity: 0.5"
                    >No hay competencias para mostrar.</em
                  >
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <!-- actividades por competencia -->
        <div class="col-xl-6 mb-3">
          <label for=" " class="form-label"
            ><em class="text-danger font-weight-bolder">Paso 3</em> -
            Seleccionar Actividades
            <span v-show="listaActividades.length" class="text-primary"
              >{{ planMejoramiento.actividadesId.length }}/{{
                listaActividades.length
              }}</span
            ></label
          >
          <b-form-select
            required
            v-model="planMejoramiento.actividadesId"
            :select-size="8"
            multiple
          >
            <template #first>
              <b-form-select-option selected :value="null" disabled
                >-- selecciona una opción --</b-form-select-option
              >
            </template>
            <b-form-select-option
              :value="it.id"
              v-for="(it, index) of listaActividades"
              :key="it.id"
              >{{ index + 1 }}. {{ it.nombre }}</b-form-select-option
            >
          </b-form-select>
          <b-button
            v-show="planMejoramiento.competenciaId"
            class="my-2"
            variant="danger"
            v-b-modal.my-modal
            ><i class="fas fa-wrench"></i> Administrar actividades</b-button
          >
        </div>

        <!-- descripcion -->
        <div class="col-xl-6 mb-2">
          <label for=" " class="form-label"
            ><em class="text-danger font-weight-bolder">Paso 4</em> - Escriba
            una Descripción</label
          >
          <b-form-textarea
            v-model="planMejoramiento.descripcion"
            placeholder="Escriba una descripción..."
            rows="7"
            minlength="10"
            maxlength="255"
            required
          ></b-form-textarea>
        </div>

        <!-- starDate -->
        <div class="col-xl-6">
          <label for=" " class="form-label"
            ><em class="text-danger font-weight-bolder">Paso 5</em> -
            Seleccionar Fecha inicio</label
          >
          <input
            required
            v-model="planMejoramiento.fechainicio"
            type="date"
            class="form-control"
            id=" "
          />
        </div>

        <!-- endDate -->
        <div class="col-xl-6">
          <label for=" " class="form-label"
            ><em class="text-danger font-weight-bolder">Paso 6</em> -
            Seleccionar Fecha fin</label
          >
          <input
            required
            v-model="planMejoramiento.fechaFin"
            type="date"
            class="form-control"
            id=" "
          />
        </div>
      </div>
      <div class="text-right px-5 pb-4 d-flex justify-content-end">
        <vs-button type="submit" class="mx-1">
          Guardar Plan Mejoramiento
        </vs-button>
      </div>
    </form>

    <!-- modal administrar actividades -->
    <b-modal
      title="Administrar Actividades"
      id="my-modal"
      size="lg"
      hide-footer
    >
      <!-- msg no existe actividades -->
      <p v-show="!listaActividades.length">
        No existen actividades para esta competencia ...
        <a
          href="#"
          @click="() => (isCreatedActivity = !isCreatedActivity)"
          class="text-danger"
        >
          {{ !isCreatedActivity ? "Crear nueva" : "Cancelar" }}</a
        >
      </p>

      <!-- list actividad -->
      <a
        v-show="
          (listaActividades.length && !isCreatedActivity) ||
          (listaActividades.length && isCreatedActivity)
        "
        href="#"
        @click="() => (isCreatedActivity = !isCreatedActivity)"
        :class="`mb-2 btn ${!isCreatedActivity ? 'btn-primary' : 'btn-danger'}`"
        ><i v-if="!isCreatedActivity" class="fas fa-plus-circle"></i
        >{{ !isCreatedActivity ? "Crear nueva" : "Cancelar" }}</a
      >

      <div v-if="listaActividades.length && !isCreatedActivity" class="row p-4">
        <h3 class="mb-2 text-white-50">
          <strong> Lista de actividades </strong>
        </h3>
        <template v-if="listaActividades.length">
          <div
            class="col-12 mb-2"
            v-for="(act, index) of listaActividades"
            :key="act.id"
          >
            <b-button
              block
              v-b-toggle="`collapse-${index}`"
              class="d-flex justify-content-between align-items-center"
              variant="dark"
              ><span class="text-truncate"
                >{{ index + 1 }}. {{ act.nombre }}</span
              >&nbsp; <i class="fas fa-chevron-down"></i
            ></b-button>
            <b-collapse :id="`collapse-${index}`" class="mt-2">
              <b-card class="position-relative">
                <small
                  class="position-absolute text-danger pointer"
                  @click="deleteActividad(act.id)"
                  style="right: 5px; font-size: 15px; top: 5px"
                  ><i class="fas fa-trash text-white-50"></i>
                </small>
                <h4 class="mb-2">
                  <strong> Información de actividad </strong>
                </h4>
                <p class="card-text">
                  <b>Nombre</b> <br />
                  {{ act.nombre }}
                </p>
                <p class="card-text">
                  <b>Descripcion</b>
                  <br />
                  {{ act.descripcion }}
                </p>
                <!-- actions for actividades -->
                <div class="d-flex flex-column" style="gap: 20px">
                  <!-- editar actividad -->
                  <b-button v-b-toggle="`collapse-${index}-inner`"
                    >Editar Actividad <i class="fas fa-pen"></i
                  ></b-button>
                  <b-collapse :id="`collapse-${index}-inner`" class="mt-2">
                    <b-card>
                      <form @submit.prevent="sendUpdateActividad(act)">
                        <div class="d-flex flex-column justify-content-between">
                          <!-- nombre -->
                          <div class="w-100">
                            <label for=" " class="form-label">Nombre</label>
                            <input
                              required
                              v-model="act.updateAct.nuevoNombre"
                              @input="act.changeValue = true"
                              placeholder="Escriba el nombre de la actividad..."
                              type="text"
                              class="form-control"
                              id=" "
                            />
                          </div>
                          <!-- descripcion -->
                          <div class="w-100 mt-2">
                            <label for=" " class="form-label"
                              >Descripción</label
                            >
                            <b-form-textarea
                              v-model="act.updateAct.nuevaDescripcion"
                              @input="act.changeValue = true"
                              placeholder="Escriba una descripción..."
                              minlength="10"
                              maxlength="255"
                              required
                            ></b-form-textarea>
                          </div>
                          <div class="text-right mt-3">
                            <b-button
                              block
                              type="submit"
                              variant="primary"
                              :disabled="!act.changeValue"
                            >
                              <i class="fas fa-save"></i> Guardar cambios
                            </b-button>
                          </div>
                        </div>
                      </form>
                    </b-card>
                  </b-collapse>
                </div>
              </b-card>
            </b-collapse>
          </div>
        </template>
        <p v-else>cargando actividades...</p>
      </div>

      <!-- create actividad -->
      <form v-show="isCreatedActivity" @submit.prevent="createActividad">
        <h4 class="py-4"><b>Nueva Actividad</b></h4>
        <div class="row pb-5">
          <!-- nombre -->
          <div class="col-12 mb-2">
            <label for=" " class="form-label">Nombre</label>
            <input
              required
              v-model="newActividad.nombre"
              placeholder="Escriba el nombre de la actividad..."
              type="text"
              class="form-control"
              id=" "
            />
          </div>
          <!-- descripcion -->
          <div class="col-12 mb-2">
            <label for=" " class="form-label">Descripción</label>
            <b-form-textarea
              v-model="newActividad.descripcion"
              placeholder="Escriba una descripción..."
              minlength="10"
              maxlength="255"
              rows="4"
              required
            ></b-form-textarea>
          </div>
        </div>
        <div class="text-right pb-4">
          <b-button type="submit" variant="primary" class="mx-1 px-4 py-2">
            Guardar Actividad
          </b-button>
        </div>
      </form>
    </b-modal>
  </HXContentCard>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);

const Swal = require("sweetalert2");
export default {
  name: "PlanMejoramiento_agregar",
  data() {
    return {
      persona: null,
      listaPersonas: [],
      personaUbicacion: null,
      cargoCompetencias: [],
      listaActividades: [],
      isCreatedActivity: null,
      resultadoEvaluaciones: [],

      newActividad: {
        nombre: null,
        descripcion: null,
        competenciaId: null,
        porcentajeCierre: null,
        estado: null,
        usuarioAsignadoId: null,
        usuarioResponsableId: null,
        empresaId: null,
      },
      updateActividad: {
        actividadId: null,
        nuevoNombre: null,
        nuevaDescripcion: null,
      },
      newActividadProgreso: {
        planYMejoramientoId: null,
        actividadId: null,
        porcentajeAut: 0,
        comentarioAut: "",
        porcentajeResp: 0,
        comentarioResp: "",
        fechaCreacion: null,
        fechaCierre: null,
      },
      planMejoramiento: {
        personaId: null,
        responsableId: null,
        competenciaId: null,
        actividadesId: [],
        descripcion: null,
        fechainicio: null,
        fechaFin: null,
        estadoPlan: 0,
      },
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    await this.getData();
    await this.getPersonas();

    this.agregarPromedioAListaPersona(
      this.listaPersonas,
      this.resultadoEvaluaciones
    );
    this.$isLoading(false);
  },
  methods: {
    agregarPromedioAListaPersona(listaPersona, resultadoEvaluaciones) {
      listaPersona.forEach((persona) => {
        const evaluacionesDePersona = resultadoEvaluaciones.filter(
          (evaluacion) => evaluacion.perPrincipal === persona.id
        );

        if (evaluacionesDePersona.length > 0) {
          const sumaValoraciones = evaluacionesDePersona.reduce(
            (acumulador, evaluacion) => {
              return acumulador + evaluacion.valoracion;
            },
            0
          );

          let promedio = sumaValoraciones / evaluacionesDePersona.length;
          promedio = parseFloat(promedio.toFixed(2)); // Redondear a 2 decimales y convertir a número
          // Agregar el promedio al objeto persona
          persona.promedioEval = promedio;

          // Para calcular el promedio por competenciaId
          const competencias = {};
          evaluacionesDePersona.forEach((evaluacion) => {
            if (!competencias[evaluacion.competenciaId]) {
              competencias[evaluacion.competenciaId] = { suma: 0, cuenta: 0 };
            }
            competencias[evaluacion.competenciaId].suma +=
              evaluacion.valoracion;
            competencias[evaluacion.competenciaId].cuenta += 1;
          });

          // Calcular el promedio para cada competenciaId y almacenarlo
          for (const competenciaId in competencias) {
            const { suma, cuenta } = competencias[competenciaId];
            competencias[competenciaId].promedio = parseFloat(
              (suma / cuenta).toFixed(2)
            );
          }

          persona.promedioPorCompetencia = competencias;
        } else {
          // Si no hay evaluaciones para la persona, podemos asignar un valor predeterminado como null o 0.
          persona.promedioEval = null;
          persona.promedioPorCompetencia = null;
        }
      });
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path:
            "PlanYMejoramiento/GetListResultadoEvaluacionesByEmpresa/" +
            this.persona.empresa.id,
        });
        console.log("list resultadoEvaluaciones...", res);
        if (res.length > 0) {
          this.resultadoEvaluaciones = res;
        } else {
          this.resultadoEvaluaciones = [];
        }
      } catch (error) {
        this.resultadoEvaluaciones = [];
        console.log("err", error);
      }
    },
    async saveData() {
      try {
        let tempActividadesId = this.planMejoramiento.actividadesId;
        console.log(this.planMejoramiento);
        let res = await this.$store.dispatch("hl_post", {
          path: "PlanYMejoramiento/",
          data: {
            ...this.planMejoramiento,
            actividadesId: this.planMejoramiento.actividadesId.join(),
            responsableId: this.persona.id,
          },
        });
        console.log(res);
        if (res) {
          for (let actividadId of tempActividadesId) {
            try {
              const datos = {
                ...this.newActividadProgreso,
                planYMejoramientoId: res.id,
                actividadId: actividadId,
                fechaCreacion: this.$moment
                  .tz(new Date(), "America/Bogota")
                  .format("YYYY-MM-DDTHH:mm"),
                fechaCierre: this.$moment
                  .tz(new Date(), "America/Bogota")
                  .format("YYYY-MM-DDTHH:mm"),
              };
              await this.createActividadProgreso(datos);
            } catch (error) {
              console.error(
                "Error al crear ActividadProgreso para actividadId:",
                actividadId,
                error
              );
            }
          }
          Swal.fire({
            title: "Listo!",
            text: "Registro almacenado correctamente",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#1A5CFF",
            cancelButtonColor: "rgb(242, 19, 67)",
            confirmButtonText: "OK",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              console.log("confirmado");
              this.initFormPlanMejoramiento();
              this.$router.push("/helex/PlanMejoramiento");
            }
          });
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async sendUpdateActividad(act) {
      console.log(act);
      try {
        let data = act.updateAct;
        let res = await this.$store.dispatch("hl_post", {
          path: "Actividad/UpdateActividadNombreDescripcion/",
          data,
        });
        console.log(res);
        if (res) {
          Swal.fire("Listo!", "Registro actualizado correctamente.", "success");
          act.nombre = act.updateAct.nuevoNombre;
          act.descripcion = act.updateAct.nuevaDescripcion;
          act.changeValue = false;
        } else {
          Swal.fire("Ops!", "No fue posible actualizar el registro.", "error");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async createActividadProgreso(newActividadProgreso) {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "Actividad/CreateActividadProgreso/",
          data: newActividadProgreso,
        });
        console.log(res);
        return res; //true o false
      } catch (error) {
        console.log("err", error);
      }
    },
    async createActividad() {
      try {
        let data = {
          ...this.newActividad,
          competenciaId: this.planMejoramiento.competenciaId,
          porcentajeCierre: 100,
          estado: 1,
          usuarioAsignadoId: this.planMejoramiento.personaId,
          usuarioResponsableId: this.persona.id,
          empresaId: this.persona.empresa.id,
        };
        let res = await this.$store.dispatch("hl_post", {
          path: "Actividad/CreateActividad/",
          data,
        });
        console.log(res);
        if (res) {
          Swal.fire({
            title: "Listo!",
            text: "Registro almacenado correctamente",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#1A5CFF",
            cancelButtonColor: "rgb(242, 19, 67)",
            confirmButtonText: "OK",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              console.log("confirmado");
              this.initFormCreateActividad();
              this.getActividadesByCompetenciaId(
                this.planMejoramiento.competenciaId
              );
              this.isCreatedActivity = false;
            }
          });
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    initFormPlanMejoramiento() {
      for (let prop in this.planMejoramiento) {
        if (prop === "actividadesId") {
          this.planMejoramiento[prop] = [];
        } else {
          this.planMejoramiento[prop] = null;
        }
      }
    },

    initFormCreateActividad() {
      this.newActividad.nombre = null;
      this.newActividad.descripcion = null;
    },
    async deleteActividad(actividadId) {
      console.log("-------------->delete", actividadId);
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1A5CFF",
        cancelButtonColor: "rgb(242, 19, 67)",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.$store.dispatch("hl_get", {
            path: "Actividad/Delete/" + actividadId,
          });
          if (res) {
            this.getActividadesByCompetenciaId(
              this.planMejoramiento.competenciaId
            );
            Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
          } else {
            Swal.fire(
              "Actividad en procesos!",
              "La actividad se encuentra asignada en uno o varios planes de mejoramiento, NO es posible eliminarla.",
              "warning"
            );
          }
        }
      });
    },
    async getPersonas() {
      try {
        console.log(
          "get personas/////////////////////////////////////////////..."
        );
        let res = await this.$store.dispatch("hl_get", {
          path: `${
            this.persona.rol.nivel < 3
              ? "Persona/ListPersonaJefe/" + this.persona.id
              : "Persona/ListPersona/" + this.persona.empresa.id
          }`,
        });
        console.log("...listapersonas", res);
        if (res.length > 0) {
          this.listaPersonas = res;
        } else {
          this.listaPersonas = [];
        }
      } catch (error) {
        this.listaPersonas = [];
        console.log("err", error);
      }
    },
    async getPersonaUbicacion(personaId) {
      // limpiar valores
      if (this.planMejoramiento.competenciaId || this.listaActividades.length) {
        this.planMejoramiento.competenciaId = null;
        this.listaActividades = [];
      }
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaUbicacion/GetByPersona/" + personaId,
        });
        console.log("personaUbicacion...", res);
        this.personaUbicacion = res;
        this.getCargoCompetencia(res.cargo.id);
      } catch (error) {
        this.personaUbicacion = null;
        console.log("err", error);
      }
    },
    async getActividadesByCompetenciaId(competenciaId) {
      console.log(competenciaId);
      this.isCreatedActivity = false;
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Actividad/GetActividadByCompetenciaId/" + competenciaId,
        });
        console.log("actividadesByCompetenciaId...", res);
        if (res.length > 0) {
          this.listaActividades = res.map((act) => ({
            ...act,
            changeValue: false,
            updateAct: {
              actividadId: act.id,
              nuevoNombre: act.nombre,
              nuevaDescripcion: act.descripcion,
            },
          }));
        } else {
          this.listaActividades = [];
        }
      } catch (error) {
        this.listaActividades = [];
        console.log("err", error);
      }
    },
    async getCargoCompetencia(cargoId) {
      console.log(cargoId);
      this.isCreatedActivity = false;
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "CargoCompetencia/ListByCargo/" + cargoId,
        });
        console.log("cargoCompetencia...", res);
        this.cargoCompetencias = res.length > 0 ? res : [];

        // Obtener la persona correspondiente de la lista de personas
        const persona = this.listaPersonas.find(
          (p) => p.id === this.planMejoramiento.personaId
        );

        if (persona && persona.promedioPorCompetencia) {
          // Agregar el promedio de la competencia a cada competencia en cargoCompetencias
          this.cargoCompetencias.forEach((cargoCompetencia) => {
            const competenciaId = cargoCompetencia.competencia.id;
            const promedioInfo = persona.promedioPorCompetencia[competenciaId];

            if (promedioInfo) {
              cargoCompetencia.competencia.promedio = promedioInfo.promedio;
            } else {
              cargoCompetencia.competencia.promedio = null;
            }
          });
        }
      } catch (error) {
        this.cargoCompetencias = [];
        console.log("err", error);
      }
    },
  },
};
</script>

<style scoped type="scss">
@import url("https://unpkg.com/vue-select@3.20.2/dist/vue-select.css");
</style>
